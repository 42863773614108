// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-b-2-bcloud-js": () => import("../src/pages/b2bcloud.js" /* webpackChunkName: "component---src-pages-b-2-bcloud-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-mobilesuite-js": () => import("../src/pages/mobilesuite.js" /* webpackChunkName: "component---src-pages-mobilesuite-js" */),
  "component---src-pages-salesrep-js": () => import("../src/pages/salesrep.js" /* webpackChunkName: "component---src-pages-salesrep-js" */)
}

